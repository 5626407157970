<template>
  <div>
    <div class="row">
      <a
        href="#"
        class="col-lg-4 mt-2 text-nowrap"
        data-toggle="modal"
        data-target="#resetPasswordModal"
      >
        {{ title }}
      </a>
    </div>
    <!-- Reset Password Modal -->
    <div
      class="modal fade"
      id="resetPasswordModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="resetPasswordModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="resetPasswordModalTitle">
              {{ title }}
            </h5>
            <button
              ref="close_modal"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetForm"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitPasswordChange">
            <div class="modal-body">
              <div class="row mt-2">
                <label class="col-sm-4" for="new-password-input">
                  Current Password:
                </label>
                <input
                  v-model="currentPassword"
                  type="password"
                  class="col-sm-7 form-control"
                  name="currentPassword"
                  id="current-password-input"
                />
              </div>
              <div class="row mt-2">
                <label class="col-sm-4" for="new-password-input">
                  New Password:
                </label>
                <input
                  v-model="newPassword"
                  type="password"
                  class="col-sm-7 form-control"
                  name="newPassword"
                  id="new-password-input"
                />
              </div>
              <div class="row" v-if="showPasswordMessage">
                <p class="text-danger col-md-12">This field is required.</p>
              </div>
              <div class="row mt-2">
                <label class="col-sm-4" for="confirm-password-input">
                  Confirm Password:
                </label>
                <input
                  class="col-sm-7 form-control"
                  type="password"
                  v-model="confirmPassword"
                  id="confirm-password-input"
                  name="confirmPassword"
                />
              </div>
              <div class="row" v-if="showMissingConfirmPasswordMessage">
                <p class="text-danger col-md-12">
                  This field is required.
                </p>
              </div>
              <div class="row" v-if="showMismatchPasswordMessage">
                <p class="text-danger col-md-12">
                  Passwords do not match.
                </p>
              </div>
              <div class="row" v-if="doesNotMatchStandard">
                <p class="text-danger col-md-12">
                  The password does not match the requirements. Please see
                  below.
                </p>
              </div>
              <div class="row">
                <p class="text col-md-12">
                  *Password must contain at least 6 characters, one lowercase
                  letter, one uppercase letter and one special character.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <div class="row">
                <button
                  class="btn purple-btn"
                  type="button"
                  :disabled="isSubmitDisabled"
                  @click="changePassword"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="vld-parent">
      <loading :active.sync="isLoading" :can-cancel="false"></loading>
    </div>
  </div>
</template>

<script>
import { userService } from "../../_services/index";
import Loading from "vue-loading-overlay";
export default {
  props: {
    title: {
      type: String,
      default: "Change Password",
    },
  },
  components: {
    Loading,
  },
  data() {
    return {
      isSubmitDisabled: false,
      doesNotMatchStandard: false,
      userName: "",
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      showPasswordMessage: false,
      showMissingConfirmPasswordMessage: false,
      showMismatchPasswordMessage: false,
      isLoading: false,
    };
  },
  methods: {
    changePassword() {
      if (!this.newPassword) {
        this.showPasswordMessage = true;
        return;
      } else if (!this.confirmPassword) {
        this.showPasswordMessage = false;
        this.showMissingConfirmPasswordMessage = true;
        return;
      } else if (this.newPassword !== this.confirmPassword) {
        this.showPasswordMessage = false;
        this.showMissingConfirmPasswordMessage = false;
        this.showMismatchPasswordMessage = true;
        return;
      } else if (!userService.validatePasswordRequirements(this.newPassword)) {
        this.doesNotMatchStandard = true;
        return;
      }
      this.isLoading = true;
      this.isSubmitDisabled = true;
      userService.submitPasswordChange(this.currentPassword, this.newPassword, this.confirmPassword).then(
        () => {
          this.isLoading = false;
          this.$toast.success("Successfully changed password", {
            position: "bottom-center",
            timeout: 2000,
            hideProgressBar: true,
          });
          setTimeout(() => {
            
            this.$refs.close_modal.click()
          }, 2500);
        },
        (error) => {
          this.isLoading = false;
          this.$toast.error(error.response.data, {
            position: "bottom-center",
            timeout: 5000,
            hideProgressBar: true,
          });
        }
      );
    },
    resetForm() {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmPassword = "";
    },
  },
};
</script>

<style></style>
