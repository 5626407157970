<template>
    <div class="col-lg-8 offset-lg-2">
      <main>
        <div class="row">
          <h3 mb-4 class="purple-title green-underline col-md-12">My Account</h3>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2">
            <label for="organization">Organization:</label>
          </div>
          <div class="col-lg-6">
            <input
              v-model="userProfile.organization"
              disabled
              class="form-control"
              id="organization"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2">
            <label for="username">Username:</label>
          </div>
          <div class="col-lg-4">
            <input
              v-model="userProfile.contactEmail"
              disabled
              class="form-control"
              id="username"
            />
          </div>
          <div class="col-lg-3 mt-2">
            <label
              ><span class="mr-4">Status: </span
              ><span class="ml-4">Active</span></label
            >
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2">
            <label for="email">Email:</label>
          </div>
          <div class="col-lg-4">
            <input
              v-model="userProfile.contactEmail"
              class="form-control"
              id="email"
            />
          </div>
          <div class="col-lg-2 mt-2">
            <label for="isManager">Is Manager?:</label>
          </div>
          <div class="col-lg-2 ">
            <select
              id="isManager"
              v-model="userProfile.isManager"
              disabled
              class="form-control"
              ><option value="no">No</option
              ><option value="yes">Yes</option></select
            >
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2 mt-2">
            <label for="firstName">First Name:</label>
          </div>
          <div class="col-lg-3">
            <input
              class="form-control"
              id="firstName"
              v-model="userProfile.firstName"
            />
          </div>
          <div class="col-lg-2 mt-2">
            <label for="lastName">Last Name:</label>
          </div>
          <div class="col-lg-3">
            <input
              v-model="userProfile.lastName"
              class="form-control"
              id="lastName"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2">
            <label for="certifications">Certifications:</label>
          </div>
          <div class="col-lg-6">
            <input
              v-model="userProfile.certifications"
              class="form-control"
              id="certifications"
            />
          </div>
          <div class="col-lg-12">
            <span><i>comma delimited accronyms</i></span>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2">
            <label for="address">Address:</label>
          </div>
          <div class="col-lg-8">
            <input
              v-model="userProfile.address"
              class="form-control"
              id="address"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2 mt-2">
            <label for="city">City:</label>
          </div>
          <div class="col-lg-3">
            <input v-model="userProfile.city" class="form-control" id="city" />
          </div>
          <div class="col-lg-1 mt-2">
            <label for="state">State:</label>
          </div>
          <div class="col-lg-2">
            <input v-model="userProfile.state" class="form-control" id="state" />
          </div>
          <div class="col-lg-1 mt-2">
            <label for="zip">Zip:</label>
          </div>
          <div class="col-lg-2">
            <input v-model="userProfile.zipCode" class="form-control" id="zip" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-2 mt-2">
            <label for="primaryPhone">
              Contact Phone:
            </label>
          </div>
          <div class="col-lg-3">
            <input
              v-model="userProfile.contactPhone1"
              class="form-control"
              id="primaryPhone"
            />
          </div>
          <div class="col-lg-2 mt-2">
            <label for="secondaryPhone">
              Secondary Phone:
            </label>
          </div>
          <div class="col-lg-3">
            <input
              v-model="userProfile.contactPhone2"
              class="form-control"
              id="secondaryPhone"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-3 mb-4">
            <ChangePasswordModal title="Change password" />
          </div>
          <div class="col-lg-6"></div>
          <div class="col-lg-3">
            <button
              @click="saveChanges(userProfile)"
              class="btn purple-btn btn-shadow"
            >
              <FontAwesomeIcon icon="save" /> &nbsp;&nbsp;Save Changes
            </button>
          </div>
        </div>
      </main>
    </div>
  </template>
  
  <script>
  import { userService } from "../../_services/index";
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import ChangePasswordModal from "../LandingPage/ChangePasswordModal";
  export default {
    data() {
      return {
        userProfile: {
          accountEmail: "",
          organization: "",
          isManager: "no",
          contactEmail: "",
          firstName: "",
          lastName: "",
          certifications: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          contactPhone1: "",
          contactPhone2: "",
        },
      };
    },
    mounted() {
      // add loading here
      this.$emit("set-loading", true);
      userService.getProfile().then(
        (response) => {
          this.userProfile = response.data;
          this.$emit("set-loading", false);
        },
        () => {
          this.$emit("set-loading", false);
          this.$router.push("/");
        }
      );
    },
    components: {
      FontAwesomeIcon,
      ChangePasswordModal,
    },
    methods: {
      saveChanges(userProfile) {
        // add loading here
        this.$emit("set-loading", true);
        userService.updateProfile(userProfile).then(
          (response) => {
            this.userProfile = response.data;
            this.$emit("set-loading", false);
            this.$toast.success("Profile Successfully Updated!", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          },
          () => {
            this.$emit("set-loading", false);
            this.$toast.error("Failed to Create Dining Space.", {
              position: "bottom-center",
              timeout: 5000,
              hideProgressBar: true,
            });
          }
        );
      },
    },
  };
  </script>
  
  <style></style>
  